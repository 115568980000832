import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const PostJobState = {
  addPostJob: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-job`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  getPostJob: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(`${BASE_URL}/api/v1/admin/get-job`, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // console.log("response", response);
    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  getPostJobID: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/admin/get-jobbyid/${id}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    // console.log("response", response);
    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  deletePostJob: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-job/${id}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  updatePostJob: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/update-job/${id}`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
  },
};

export default PostJobState;
