import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaCircleArrowUp } from "react-icons/fa6";
const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to check scroll position
  const toggleVisibility = () => {
    if (window.scrollY > window.innerHeight * 0.4) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Function to scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Attach event listener on mount & cleanup on unmount
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <Button
          onClick={scrollToTop}
          style={{ bottom: "90px", right: "10px", zIndex: 25 }}
          className="position-fixed"
        >
          <FaCircleArrowUp size={35} color="#e3a02c" />
        </Button>
      )}
    </>
  );
};
export default BackToTop;
