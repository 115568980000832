import React from "react";
const RbLogo = require("../../../images/logo-light.png");

const Loader = () => {
  return (
    <>
      <div className="loader-container bg-light w-100 h-100 d-flex align-items-center justify-content-center position-absolute">
        <div className="position-relative overflow-hidden">
          <img className="180px" width="180px" src={RbLogo} alt="" />
          <div className="gradiant-fade-animate"></div>
        </div>
      </div>
    </>
  );
};

export default Loader;
