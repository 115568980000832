import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const AppointmentState = {
  addAppointment: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-appointment`,
      requestOptions,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    return datas;
  },

  getAppointment: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/admin/get-appointment`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    //console.log("response", response);
    let datas = response.data.data;
    //console.log("datas", datas);
    return datas;
  },

  deleteAppointment: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-appointment/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  updateAppointment: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/update-appointment/${id}`,
      requestOptions
    );
  },
};

export default AppointmentState;
