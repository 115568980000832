import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const DepartmentState = {
  addDepartment: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-department`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    return datas;
  },

  getDepartment: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(
      `${BASE_URL}/api/v1/admin/get-department`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    //console.log("response", response);
    let datas = response.data.data;
    //console.log("datas", datas);
    return datas;
  },

  deleteDepartment: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-department/${id}`,
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  updateDepartment: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/update-department/${id}`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
  },
};

export default DepartmentState;
